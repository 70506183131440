@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .bg-gradient {
    --gradient-pink-yellow: linear-gradient(
      calc(90deg + var(--gradient-vertical, 1) * 90deg),
      /*
      black 0%,
      */ var(--tw-esc-yellow) 2px,
      var(--tw-esc-yellow),
      var(--tw-esc-pink),
      var(--tw-esc-orange) 45%,
      var(--tw-esc-orange) 55%,
      var(--tw-esc-pink),
      var(--tw-esc-yellow)
    );

    --gradient-turquoise-yellow: linear-gradient(
      calc(90deg + var(--gradient-vertical, 1) * 90deg),
      /*
      black 0%,
      var(--tw-esc-yellow) 2px,
      */
        var(--tw-esc-yellow),
      var(--tw-esc-turquoise),
      var(--tw-esc-purple) 40%,
      var(--tw-esc-purple) 60%,
      var(--tw-esc-turquoise),
      var(--tw-esc-yellow)
    );
  }

  .bg-gradient-bar.bg-gradient-vertical {
    --gradient-offset: -10%;
    --animation-offset: 433%;
    --background-position-x: 0%;
    --background-position-y: calc(var(--gradient-offset) * var(--index));

    --background-position-y-animated: calc(
      (var(--gradient-offset) * var(--index)) + var(--animation-offset)
    );

    left: calc(var(--position) * 1%);
    width: calc(var(--size) * 1%);
    background-size: 100% 130%;
  }

  .bg-gradient-bar.bg-gradient-horizontal {
    --gradient-vertical: 0;
    --gradient-offset: 25%;
    --animation-offset: -433%;
    --background-position-x: calc(var(--gradient-offset) * var(--index));
    --background-position-x-animated: calc(
      (var(--gradient-offset) * var(--index)) + var(--animation-offset)
    );
    --background-position-y: 0%;

    top: calc(var(--position) * 1%);
    height: calc(var(--size) * 1%);
    background-size: 130% 100%;
  }

  .bg-gradient-bar {
    background-position: var(--background-position-x)
      var(--background-position-y);
  }

  .bg-gradient-bar-animated {
    animation: gradient 6s ease-in-out infinite;
    animation-delay: calc(var(--index) * 300ms);
  }

  @keyframes gradient {
    0% {
      background-position: var(--background-position-x)
        var(--background-position-y);
    }
    100% {
      background-position: var(--background-position-x-animated, 0%)
        var(--background-position-y-animated, 0%);
    }
  }

  .bg-gradient-pink-yellow {
    background-image: var(--gradient-pink-yellow), var(--gradient-pink-yellow),
      var(--gradient-pink-yellow), var(--gradient-pink-yellow),
      var(--gradient-pink-yellow), var(--gradient-pink-yellow),
      var(--gradient-pink-yellow), var(--gradient-pink-yellow);
  }

  .bg-gradient-turquoise-yellow {
    background-image: var(--gradient-turquoise-yellow),
      var(--gradient-turquoise-yellow), var(--gradient-turquoise-yellow),
      var(--gradient-turquoise-yellow), var(--gradient-turquoise-yellow),
      var(--gradient-turquoise-yellow), var(--gradient-turquoise-yellow),
      var(--gradient-turquoise-yellow);
  }
}
