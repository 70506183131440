.App {
  background-color: #282c34;
  color: #fff;
  font-size: 1.25rem;
  min-height: 100vh;
}

.App * {
  box-sizing: border-box;
}

/* .Turborepo {
  font-size: 72px;
  background: linear-gradient(to right, #0099f7, #f11712);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App-header {
  background-color: #18181b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: white;
} */
